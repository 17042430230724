import * as React from "react"
import Layout from "../components/layout"
import Contact from "../components/sections/contact"
import AboutGuides from "../components/sections/index/about_guides"
import AboutSysphar from "../components/sections/index/about_sysphar"
import AboutWiki from "../components/sections/index/about_wiki"
import CarouselProjects from "../components/sections/index/carousel_projects"
import Welcome from "../components/sections/index/welcome"
import Seo from "../components/seo"

const IndexPage = props => (
  <Layout language="pt" pathname={props.location.pathname}>
    <Seo
      title="Home"
      lang="pt"
      description="Olá, somos CAMARADES Brasil! Nosso site oferece ferramentas úteis para sua revisão sistemática de literatura"
    />
    <Welcome language="pt" />
    <CarouselProjects language="pt" />
    <AboutGuides language="pt" />
    <AboutWiki language="pt" />
    <AboutSysphar language="pt" />
    <Contact language="pt" />
  </Layout>
)

export default IndexPage
